
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import SecondaryToolbar from "./layout/SecondaryToolbar.vue";

export default Vue.extend({
  components: { SecondaryToolbar },
  name: "crm-contacts-main",
  mounted() {
    (this as any).$parent.$parent.$parent.topBar = true;

    if (this.$vuetify.breakpoint.mobile == false) {
      (this as any).$parent.$parent.$parent.drawer = true;
    }
  },
});
